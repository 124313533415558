<template>
    <Error :errors="errors"/>
    <div class="grid">
		<div class="col-12">
            <div class="card">         
                <Datatable :survey="4" ref="dt"/>
            </div>
        </div>
    </div>
</template>


<script>
import { mapGetters } from "vuex";
import Datatable from '../../components/aktifitas/Datatable.vue';
import Error from '../../components/Error.vue';

export default {
    components: {
		'Datatable': Datatable,
		'Error': Error,
	},
    data() {
        return {
        }
    },
    mounted() {
        this.$store.commit('setErrors', {});
    },
    watch: {
    },
    computed:{
        ...mapGetters(['errors']),
    },
    methods: {
    }
}
</script>
