<template>
    <DataTable :value="dataTable" responsiveLayout="scroll" :loading="loading" dataKey="cust_id" @sort="onSort($event)" >
        <template #header>
            <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                <div>
                   <h5>Survey {{this.survey}}</h5>
                </div>
                <span class="block mt-2 md:mt-0 p-input-icon-left">
                    <i class="pi pi-search" />
                    <InputText v-model="search" placeholder="Cari..." @keyup.enter="getDataTable" />
                </span>
            </div>
        </template>
        <Column field="number" header="No." :style="{width:'20px'}" >
            <template #body="slotProps">
            {{ slotProps.data.number }}
            </template>
        </Column>
        <Column field="noHp" header="Peserta">
            <template #body="slotProps">
                {{slotProps.data.peserta?.noHp}}
            </template>
        </Column>
        <Column field="terimaHadiah" :header="'Konfirmasi Hadiah Survey ' + parseInt(this.survey-1)">
            <template #body="slotProps">
                <Button icon="pi pi-times" class="p-button-rounded p-button-danger" v-if="slotProps.data.terimaHadiah == 0"/>
                <Button icon="pi pi-check" class="p-button-rounded p-button-success" v-else/>
            </template>
        </Column>
        <Column field="link" :header="'Link Survey ' + this.survey">
            <template #body="slotProps">
                <div v-if="slotProps.data.terimaHadiah != 0">
                    {{ url }}/survey{{ survey }}/{{slotProps.data.peserta?.noHp}}
                </div>
            </template>
        </Column>
        <template #empty>
            No data found.
        </template>
        <template #loading>
            Loading data. Please wait.
        </template>
        <template #footer>
            In total there are {{totalItemsCount ? totalItemsCount : 0 }} data.
        </template>
    </DataTable>

    <Paginator v-model:rows="rows" v-model:first="offset" :totalRecords="totalItemsCount" :rowsPerPageOptions="[10,20,30]">
        <template>
            {{totalItemsCount}}
        </template>
    </Paginator>
</template>

<script>
import moment from 'moment';
import { numberingDatatable } from '../../utils/helpers';

export default {
    props: [
		"survey",
    ],
    data() {
        return {
            // loading
            loading: false,
            
            url: null,

            //datatables
            data: null,
            dataTable: null,
            rows: 10,
            search: null,
            offset: null,
            field: null,
            sort: null,
            page: null,
            totalItemsCount: 0,
        }
    },
    mounted() {
        this.getDataTable();
        this.url = window.location.hostname;
    },
    watch: {
        offset() {
            this.getDataTable();
        },
        rows() {
            this.getDataTable();
        },
    },
    methods: {
        formatDate(value){
            if (value) {
                return moment.utc(value).format('DD-MM-YYYY')
            }
        },
        // DATATABLE
        getDataTable(){
          
            this.loading=true;
            this.page = (this.offset / this.rows) + 1;

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/survey',
                params: {
                    "search" : this.search,
                    "per_page" : this.rows,
                    "page" : this.page,
                    "order_by" : this.field,
                    "sort_by" : this.sort,
                    "status" : 'survey ' + parseInt(this.survey-1)
                }
            })
            .then(res => {
              this.data = res.data.data.data;
              this.dataTable = numberingDatatable(res.data.data.data, this.page, this.rows);
              this.totalItemsCount = res.data.data.total;
              this.rows = parseInt(res.data.data.per_page);
              this.loading=false;
            })
            .catch((err) => {
                console.log(err);
            });
         
        },
        onSort(event) {
            this.field = event.sortField;
            this.sort = event.sortOrder == '1' ? 'ASC' : 'DESC';
            this.getDataTable();
        },
    }
    
}
</script>